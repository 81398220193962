import { environment } from '../../../../environments/environment';

export interface MenuItem {
  id?: string;
  route: string | null;
  icon: string;
  name: string;
  summary?: string;
  featureFlagName: keyof (typeof environment.featuresFlag);
}

export const ALL_ITEMS: MenuItem[] = [
  {
    route: '/dashboard',
    icon: 'home',
    name: 'Home',
    featureFlagName: 'home'
  },
  {
    route: '/vouchers',
    icon: 'ticket',
    name: 'Vouchers List',
    featureFlagName: 'vouchers'
  },
  {
    route: '/balance-history',
    icon: 'dollar-square',
    name: 'Balance History',
    featureFlagName: 'balanceHistory'
  },
  {
    route: '/deposit',
    icon: 'dollar-square',
    name: 'Add Money',
    featureFlagName: 'deposit'
  },
  {
    route: '/kyc',
    icon: 'security-user',
    name: 'Identity Verification',
    featureFlagName: 'identityVerification'
  },
  {
    route: null,
    icon: 'logout-menu',
    name: 'Logout',
    featureFlagName: 'logout'
  },
];
