import { Routes } from '@angular/router';
import { LayoutComponent } from '@shared/layout/layout.component';
import { accessGuard } from './pages/auth/guards/access.guard';
import { authGuard } from './pages/auth/guards/auth.guard';

export default [
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.routes'),
    canMatch: [authGuard]
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('./pages/dashboard/dashboard.component'),
        title: 'Dashboard',
      },
      {
        path: 'kyc',
        loadChildren: () => import('./pages/kyc/kyc.routes'),
      },
      {
        path: 'vouchers',
        loadComponent: () => import('./pages/vouchers/vouchers.component'),
        title: 'Vouchers',
      },
      {
        path: 'balance-history',
        loadComponent: () => import('./pages/balance-history/balance-history.component'),
        title: 'Balance History',
      },
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      {
        path: '**',
        loadComponent: () => import('./pages/not-found/not-found.component'),
        title: 'Not Found 404',
      },
    ],
    canMatch: [accessGuard]
  },
] as Routes;
